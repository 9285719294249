import { Injectable } from '@angular/core';
import { AppSections } from 'src/app/utils/enums';
import { Device } from '@capacitor/device';
import { Platform } from '@ionic/angular';
import {
  AccessorySummary,
  CalculateTax,
  Color,
  EmaginatorSection,
  Model,
  OptionCodes,
  Variant,
} from 'src/app/utils/interfaces';
import { ApiService } from 'src/app/services/API/api.service';
import { CachingService } from 'src/app/services/cache/caching.service';
import { StorageEnums } from 'src/app/utils/storage.enums';
import { EventService } from 'src/app/services/Events/events.service';
import { WidgetsService } from 'src/app/services/ui/widgets.service';
import { API_ERROR_MESSAGE } from 'src/app/utils/constants';
declare const window: any;
@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  isLoading = false;
  isModelLoaded = false;
  configurator = false;
  brandName = 'Leapmotor';
  isPostcode = true;
  // To Activate Emaginator
  // activeAppSection: AppSections = AppSections.Emaginator;
  // To Activate Visualizer
  activeAppSection: AppSections = AppSections.Visualizer;
  vehicleCode: string = '';
  optionalCodes: OptionCodes;
  model: Model;
  show_offer = '';
  selectedVariant: Variant;
  colorSelected: Color;
  trimSelected: Color;
  addedAccessories: AccessorySummary[] = [];
  addedAccessoriesPacks = [];
  variant_color_id = 0;
  trim_color_id = 0;
  buildName = '';
  emaginatorUiComponent: HTMLElement;
  defaultEmaginatorSection: EmaginatorSection;
  isViewInterior = false;
  currency = 'en-AU';
  currencySymbol = '$';
  countryCode = 'AU';
  countryPhoneCode = '+61';
  currencyNullValue = 'XXX,XXX,XXX';
  ambientSound = new Audio('assets/sounds/ambient_sound.mp3');
  muteMusic = true;
  isPlayed = false;
  postcode = 0;
  selectedConfigPrice: CalculateTax;
  showLoadingPriceText = false;
  isImageAccessoryOpen = false;
  languageCode = 'en-US';
  phoneNumberTemp = '0XXXX-XXX-XX';
  isAutoTestDriveFormDisplayed = false;
  constructor(
    private readonly apiService: ApiService,
    private readonly cachingService: CachingService,
    private readonly events: EventService,
    private readonly widgetsService: WidgetsService,
    private readonly platform: Platform
  ) {}
  // ---- Orientation Code Starts ----
  portraitView(containerId: string) {
    window.onresize = this.portraitWindowSize(containerId);
    window.onload = this.portraitWindowSize(containerId);
  }
  portraitWindowSize(containerId: string) {
    const getDeviceWidth = window.innerWidth;
    const getDeviceHeight = window.innerHeight;
    const emaginatorUiComponent = document.getElementById(containerId);
    if (emaginatorUiComponent) {
      emaginatorUiComponent.style.transform = 'rotate(90deg)';
      emaginatorUiComponent.style.margin =
        '0px 0px 0px ' + getDeviceWidth + 'px';
      emaginatorUiComponent.style.width = getDeviceHeight + 'px';
      emaginatorUiComponent.style.height = getDeviceWidth + 'px';
      emaginatorUiComponent.style.transformOrigin = '0px 0px 0px';
      window.dispatchEvent(new Event('resize'));
      setTimeout(() => {
        const container = document.getElementById('one3d-canvas-ui-wrapper');
        const width = container?.offsetWidth;
        const height = container?.offsetHeight;
        const rect = [0, 0, width, height];
        window.ONE3D?.webglRoot.resize(height, width, rect);
      }, 100);
    }
  }
  landscapeView(containerId: string) {
    window.onresize = this.landscapeWindowSize(containerId);
    window.onload = this.landscapeWindowSize(containerId);
  }
  landscapeWindowSize(containerId: string) {
    const getDeviceWidth = window.innerWidth;
    const getDeviceHeight = window.innerHeight;
    const emaginatorUiComponent = document.getElementById(containerId);
    if (emaginatorUiComponent) {
      emaginatorUiComponent.style.transform = 'rotate(0deg)';
      emaginatorUiComponent.style.margin = '0px auto';
      emaginatorUiComponent.style.width = getDeviceWidth + 'px';
      emaginatorUiComponent.style.height = getDeviceHeight + 'px';
      emaginatorUiComponent.style.transformOrigin = 'initial';
      window.dispatchEvent(new Event('resize'));
      setTimeout(() => {
        const container = document.getElementById('one3d-canvas-ui-wrapper');
        const width = container?.offsetWidth;
        const height = container?.offsetHeight;
        const rect = [0, 0, width, height];
        window.ONE3D?.webglRoot.resize(width, height, rect);
      }, 100);
    }
  }
  //  Detect Operating Systems of Mobile Devices
  getMobileSystem() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    // iOS detection for iPhones and iPods
    if (/iPhone|iPod/.test(userAgent)) {
      return 'iOS';
    }
    // iOS detection for iPads
    if (/iPad|Safari|Apple/i.test(userAgent)) {
      return 'iPad';
    }
    return 'unknown';
  }
  // Apply Orientation change
  one3dOrientationBlock(containerId = 'leapUIContainer') {
    const device = this.getMobileSystem(); // Windows Phone,Android, iOS, iPad, unknown
    //  Condition to apply orientation as per devices - iPad, Android, iOS
    switch (device) {
      case 'iPad':
        if (window.matchMedia('(orientation: portrait)').matches) {
          this.portraitView(containerId);
        } else {
          this.landscapeView(containerId);
        }
        break;
      case 'Android':
        if (window.matchMedia('(orientation: portrait)').matches) {
          this.portraitView(containerId);
        } else {
          this.landscapeView(containerId);
        }
        break;
      case 'iOS':
        if (window.matchMedia('(orientation: portrait)').matches) {
          this.portraitView(containerId);
        } else {
          this.landscapeView(containerId);
        }
        break;
      default:
        this.landscapeView(containerId);
        break;
    }
  }
  one3dOrientationBlockChangeFunction(containerId = 'leapUIContainer') {
    this.one3dOrientationBlock(containerId);
    const timeout = setTimeout(() => {
      this.one3dOrientationBlock(containerId);
      clearTimeout(timeout);
    }, 100);
  }
  // ---- Orientation Code Ends ----
  setActiveAppSection(section: AppSections) {
    this.activeAppSection = section;
  }
  getAccessToken() {
    return sessionStorage.getItem('access-token');
  }
  async getVariantAll(postcode: string | number) {
    return new Promise((resolve, reject) => {
      const requestBody = {
        model_id: this.model.model_id,
        shown_at: 'web',
      };
      if (this.isPostcode && postcode) {
        requestBody['postcode'] = postcode;
      }
      this.apiService
        .get('variants/all', requestBody, { version: 1 })
        .then(async (res: any) => {
          if (res.message === 'success') {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  async getVariantDetails(
    selectedVariant: Variant,
    updatedUIPrice: string = ''
  ) {
    const postcode = await this.cachingService.getDataFromLocal(StorageEnums.POSTCODE);
    return new Promise((resolve, reject) => {
      const requestBody = {
        variant_id: selectedVariant.variant_id,
        shown_at: 'web',
      };
      if (this.isPostcode && postcode) {
        requestBody['postcode'] = postcode;
      }
      this.apiService.get('variants/details', requestBody).then(
        async (res: any) => {
          if (res.message === 'success') {
            this.selectedVariant = selectedVariant;
            this.vehicleCode = res.response?.lms_integrated_code;
            const variantDetails = res.response.variants[0];
            this.cachingService.setDataInSession(
              StorageEnums.VARIANT_DETAILS,
              variantDetails
            );
            if (updatedUIPrice) {
              this.events.publish('events', {
                updateUIPrices: selectedVariant,
              });
            }
            this.cachingService.setDataInSession(
              StorageEnums.MODEL_DETAILS,
              res.response
            );
            this.events.publish('events', { variantDetails });
            this.events.publish('events', { variantFeatures: variantDetails });
            resolve(true);
          } else {
            this.widgetsService.toastWithButtons(
              'Something went wrong!',
              'danger'
            );
            reject();
          }
        },
        (error) => {
          this.widgetsService.hideLoader();
          this.widgetsService.toastWithButtons(
            'Something went wrong!',
            'danger'
          );
          reject();
        }
      );
    });
  }
  getTimeSpent() {
    const timer = performance.now();
    const counter = Math.round(timer / 1000);
    return String(counter);
  }
  checkBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) !== -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Edg') !== -1) {
      return 'Edge';
    } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') !== -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
      return 'Firefox';
    } else {
      return 'unknown';
    }
  }
  async setConfigData() {
    return new Promise((resolve, reject) => {
      const config = {
        colorSelected: this.colorSelected,
        trimSelected: this.trimSelected,
        selectedVariant: this.selectedVariant,
        addedAccessories: this.addedAccessories,
        addedAccessoriesPacks: this.addedAccessoriesPacks,
      };
      this.cachingService.setDataInSession(StorageEnums.CONFIG, config);
      resolve(true);
    });
  }
  async configuration(crudOperation: string) {
    return new Promise(async (resolve, reject) => {
      const deviceID = await Device.getId();
      const utm = this.cachingService.getDataFromSession(
        StorageEnums.UTM_PARAMS
      );
      const variantDetails = this.cachingService.getDataFromSession(
        StorageEnums.VARIANT_DETAILS
      );
      const variants = this.cachingService.getDataFromSession(
        StorageEnums.VARIANTS
      );
      let pin_no = this.cachingService.getDataFromSession(StorageEnums.PIN_NO);
      const modelSelected = this.cachingService.getDataFromSession(
        StorageEnums.SELECTED_MODEL
      );
      const config = this.cachingService.getDataFromSession(
        StorageEnums.CONFIG
      );
      const buildName = this.cachingService.getDataFromSession(
        StorageEnums.BUILD_NAME
      );
      const visitorInfo = await this.cachingService.getDataFromLocal(
        StorageEnums.VISITOR_ID
      );
      const configPacks = config?.addedAccessoriesPacks;
      const addedPacks = [];
      if (configPacks) {
        for (const pack of configPacks) {
          const packs = {
            accessories_id: pack.pack_id,
            price: pack.pack_price,
            accessory_type: 'Package',
            quantity: 1,
          };
          addedPacks.push(packs);
        }
      }
      const addedAccessories: any[] = config?.addedAccessories || [];
      addedAccessories.push(...addedPacks);
      const device = this.platform.platforms();
      const requestBody = {
        visitor_id: visitorInfo ? visitorInfo : '',
        variant_id: Number(variantDetails.variant_id),
        build_name: buildName,
        brand_name: variants.brand_name,
        model_id: modelSelected.model_id,
        variant_color_id: Number(config?.colorSelected?.variant_color_id || 0),
        showroom_price: Number(config?.colorSelected?.exshowroom_price || 0),
        page: this.configurator ? 'configurator' : 'visualizer',
        // page: this.activeAppSection,
        timespent: this.getTimeSpent(),
        accessories: addedAccessories,
        pin_no:
          pin_no && pin_no[this.vehicleCode] ? pin_no[this.vehicleCode] : null,
        device: device[0],
        device_id: deviceID.identifier,
        version_no: 'A',
        browser: this.checkBrowser(),
        source: this.configurator ? 'configurator' : 'visualizer',
        // source: this.activeAppSection,
        channel: 'web_configurator',
        UTMCampaign: utm?.utm_campaign || null,
        UTMSource: utm?.utm_medium || null,
        UTMContent: utm?.utm_content || null,
        UTMMedium: utm?.utm_source || null,
        country_code: '',
        is_repeat: 'new',
      };
      if (!pin_no || (pin_no && !pin_no[this.vehicleCode])) {
        crudOperation = 'create';
      }
      this.apiService.post(`configuration/${crudOperation}`, requestBody).then(
        async (data: any) => {
          if (data.message === 'success') {
            if (crudOperation === 'create') {
              const response = data.response;
              if (pin_no) {
                pin_no[this.vehicleCode] = response.pin_no;
              } else {
                pin_no = { [this.vehicleCode]: response.pin_no };
              }
              this.cachingService.setDataInSession(StorageEnums.PIN_NO, pin_no);
              this.cachingService.setDataInSession(
                StorageEnums.CONFIGURATION_ID,
                response.configuration_id
              );
              this.cachingService.setDataInLocal(
                StorageEnums.VISITOR_ID,
                response.visitor_id
              );
              this.apiService.updateReportingData('pinId', response.pin_no);
              this.apiService.sendReportingData({
                eName: 'internal',
                eCat: 'configuration',
                eAct: 'created',
              });
              this.apiService.logGAEvent(
                'uaevent',
                'pin_no',
                'Click',
                response.pin_no
              );
            }
            if (crudOperation === 'update') {
              this.apiService.sendReportingData({
                eName: 'internal',
                eCat: 'configuration',
                eAct: 'updated',
              });
            }
            if (
              config.addedAccessories.length > 0 ||
              config.addedAccessoriesPacks.length > 0
            ) {
              this.addedAccessories = config.addedAccessories;
              this.addedAccessoriesPacks = config.addedAccessoriesPacks;
            }
            this.setConfigData().then((res) => {
              this.calculateColorAccTax().then(() => {
                resolve(true);
              });
            });
          }
        },
        () => {
          this.widgetsService.toastWithButtons(API_ERROR_MESSAGE, 'danger');
          reject(false);
        }
      );
    });
  }
  async saveConfiguration(action = 'update') {
    const deviceID = await Device.getId();
    if (action === 'proceedToBook') {
      this.apiService.logGAEvent(
        'uaevent',
        'Content',
        'Click',
        'Save and Book'
      );
      this.apiService.sendReportingData({
        eName: 'click',
        eCat: 'cta_clicked_configurator',
        eAct: 'proceed_to_book',
      });
    }
    const variants = await this.cachingService.getDataFromSession(
      StorageEnums.VARIANTS
    );
    const pin_no = await this.cachingService.getDataFromSession(
      StorageEnums.PIN_NO
    );
    const modelSelected = await this.cachingService.getDataFromSession(
      StorageEnums.SELECTED_MODEL
    );
    const buildName = await this.cachingService.getDataFromSession(
      StorageEnums.BUILD_NAME
    );
    const config = await this.cachingService.getDataFromSession(
      StorageEnums.CONFIG
    );
    const utm = await this.cachingService.getDataFromSession(
      StorageEnums.UTM_PARAMS
    );
    const visitorInfo = await this.cachingService.getDataFromLocal(
      StorageEnums.VISITOR_ID
    );
    const config_id = await this.cachingService.getDataFromSession(
      StorageEnums.CONFIGURATION_ID
    );
    const configPacks = config?.addedAccessoriesPacks;
    const addedPacks = [];
    const emi = await this.cachingService.getDataFromSession(
      StorageEnums.EMI_DETAILS
    );
    if (configPacks) {
      for (const pack of configPacks) {
        const packs = {
          accessories_id: pack.pack_id,
          price: pack.pack_price,
          accessory_type: 'Package',
          quantity: 1,
        };
        addedPacks.push(packs);
      }
    }
    const device = this.platform.platforms();
    const addedAccessories = config?.addedAccessories;
    addedAccessories.push(...addedPacks);
    const requestBody = {
      visitor_id: visitorInfo ? visitorInfo : '',
      variant_id: Number(config.selectedVariant.variant_id),
      build_name: buildName,
      brand_id: variants.brand_id,
      model_id: modelSelected.model_id,
      variant_color_id: config.colorSelected.variant_color_id,
      trim_color_id: config.trimSelected
        ? config.trimSelected.variant_color_id
        : null,
      showroom_price: config.colorSelected?.exshowroom_price,
      page: this.configurator ? 'configurator' : 'visualizer',
      timespent: this.getTimeSpent(),
      accessories: addedAccessories,
      kit: [],
      pin_no:
        pin_no && pin_no[this.vehicleCode] ? pin_no[this.vehicleCode] : null,
      configuration_id: config_id ? config_id : null,
      device: device[0],
      device_id: deviceID.identifier,
      browser: this.checkBrowser(),
      UTMCampaign: utm ? utm.utm_campaign : null,
      UTMSource: utm ? utm.utm_medium : null,
      UTMContent: utm ? utm.utm_content : null,
      UTMMedium: utm ? utm.utm_source : null,
      channel: 'web_configurator',
      country_code: '',
      FinanceOptions: {
        ExShowroomPrice: emi ? emi.ExShowroomPrice : null,
        DownPayment: emi ? emi.DownPayment : null,
        TradeInValue: emi ? emi.TradeInValue : null,
        EstimatedAmountFinanced: emi ? emi.EstimatedAmountFinanced : null,
        TermDuration: emi ? emi.TermDuration : null,
        AnnualPercentageRate: emi ? emi.AnnualPercentageRate : null,
        EstimatedMonthlyPayment: emi ? emi.EstimatedMonthlyPayment : null,
      },
    };
    this.apiService.put('configuration/save', requestBody).then(
      async (data: any) => {
        if (data.message === 'success') {
          this.calculateColorAccTax().then(() => {
            return;
          });
        }
      },
      (error) => {
        console.error('Error : ', error);
        this.widgetsService.hideLoader();
        this.widgetsService.toastWithButtons(API_ERROR_MESSAGE, 'danger');
      }
    );
  }
  currencyFormatter(price: string | number) {
    if (!price) {
      return `${this.currencySymbol} 0`;
    }
    return price
      ? `${this.currencySymbol} ${Number(price).toLocaleString(this.currency)}`
      : `${this.currencySymbol} ${this.currencyNullValue}`;
  }
  currencyFormatterWithZero(price: number) {
    return !isNaN(price)
      ? `${this.currencySymbol} ${Number(price).toLocaleString(this.currency)}`
      : `${this.currencySymbol} ${this.currencyNullValue}`;
  }
  getOrdinal(num: number) {
    let suffix = 'th';
    if (num % 100 >= 11 && num % 100 <= 13) {
      suffix = 'th';
    } else {
      switch (num % 10) {
        case 1:
          suffix = 'st';
          break;
        case 2:
          suffix = 'nd';
          break;
        case 3:
          suffix = 'rd';
          break;
      }
    }
    return num + '<sup>' + suffix + '</sup>';
  }
  activateAudio() {
    this.ambientSound.setAttribute('allow', 'autoplay;');
    this.ambientSound.volume = 0.5;
    this.ambientSound.loop = true;
  }
  destroyAudio() {
    this.isPlayed = false;
    this.ambientSound.volume = 0;
    this.cachingService.setDataInSession(StorageEnums.MUTE_MUSIC, true);
    this.ambientSound.pause();
    this.ambientSound.remove();
  }
  muteAudio(value = '') {
    switch (value) {
      case 'pause':
        this.muteMusic = true;
        break;

      case 'play':
        this.muteMusic = false;
        break;

      default:
        this.muteMusic = !this.muteMusic;
        break;
    }
    this.cachingService.setDataInSession(
      StorageEnums.MUTE_MUSIC,
      this.muteMusic
    );
    this.ambientSound.muted = this.muteMusic;
  }
  capitalizeFirstLetter(sentence: string) {
    const words = sentence.split(' ');
    const capitalizedWords = words.map((word) => {
      const firstLetter = word.charAt(0).toUpperCase();
      const restOfWord = word.slice(1);
      return firstLetter + restOfWord;
    });
    return capitalizedWords.join(' ');
  }
  numberOnlyValidation(event: Event, value: number | string) {
    const pattern = /^\d+$/;
    const inputChar = String(value);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  calculateColorAccTax() {
    return new Promise(async (resolve, reject) => {
      this.showLoadingPriceText = true;
      this.selectedConfigPrice = null;
      let totalAccPrice = this.addedAccessories.reduce(
        (a, b) => Number(a) + Number(b['price'] || 0),
        0
      );

      const config_id = await this.cachingService.getDataFromSession(StorageEnums.CONFIGURATION_ID);

      const requestBody = {
        variant_cpos: this.selectedVariant?.variant_cpos,
        variant_id: this.selectedVariant?.variant_id,
        postcode: this.postcode,
        accessory_price: totalAccPrice,
        color_price: this.colorSelected?.paint_cost,
        cpos_model_id: this.selectedVariant?.cpos_model_id,
        paint_id: this.selectedVariant?.paint_id,
      };
      if (config_id) {
        requestBody['configuration_id'] = config_id;
      }

      this.apiService
        .get('variants/calculateTax', requestBody)
        .then(async (res: any) => {
          if (res.message === 'success') {
            console.log('///calculateColorAccTax response', res);
            this.selectedConfigPrice = res.response as CalculateTax;
            this.showLoadingPriceText = false;
            this.cachingService.setDataInSession(StorageEnums.SELECTED_CONFIG_PRICE, res.response);
            // added since on summary page total price was not updating on remove of acc
            this.events.publish('events', {
              updatedTotalTaxPrices: null,
            });
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((error) => {
          this.widgetsService.toastWithButtons(
            'Something went wrong!',
            'danger'
          );
          reject(error);
        });
    });
  }
  getResultantVariable(flag: boolean): string {
    return `${this.activeAppSection}_${flag ? 'Button' : 'Nudges'}`;
  }
}
